<template>
  <el-dialog
    :visible="visible"
    title="提示"
    width="468"
    append-to-body
    @close="$emit('update:visible', false)"
  >
    <div class="dialog-content">
      <p
        class="tip"
      >
        <i class="iconfont icon-error" />
        <span>{{ authMsg }}</span>
      </p>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="mini"
        @click="$emit('update:visible', false)"
      >
        取消
      </el-button>
      <el-button
        size="mini"
        type="primary"
        @click="submit"
      >
        去认证
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { environmentHref, cloudRequestPath } from '@/utils/cloudRequest';
import { getCloudToken } from '@/services/home';

export default {
  name: 'AuthenticationDialog',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    authMsg: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  methods: {
    async submit() {
      this.$emit('update:visible', false);
      const token = await getCloudToken();
      const fullPath = `${environmentHref}/#/financing/personalCenter?page=${this.type}`;
      window.open(`${cloudRequestPath}&token=${token}&redirectUrl=${fullPath}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-content {
  padding: 20px 0;
  box-sizing: border-box;

  & /deep/ .el-form {
    padding-top: 20px;
    padding-bottom: 0;
  }
}

.tip {
  font-size: 14px;
  color: #333b44;

  & .iconfont {
    font-size: 16px;
    color: #ffa55c;
    vertical-align: middle;
  }
}
</style>
